/**
 * http 状态码
 */
export enum HttpStatusCode {
  /**
   * 无权限
   */
  UNAUTHORIZED = 401,
  /**
   * 登录失效
   */
  PAYMENT_REQUIRED = 402,
  /**
   * 404
   */
  NOT_FOUND = 404,
  /**
   * 502
   */
  BAD_GATEWAY = 502,
  /**
   * 处接口返回200外的其他情况
   */
  NO_SUCCESS = 100,
  /**
   * 接口正常返回数据
   */
  SUCCESS = 200,
  /**
   * 从缓存返回数据
   */
  CACHE_SUCCESS = 600,
}

export enum GlobalEventType {
  /**
   * 打开快捷键
   */
  KEYBOARD_SHORTCUTS = 'KEYBOARD_SHORTCUTS',
  /**
   * 保存表单
   */
  SAVE = 'SAVE',
  /**
   * 提交表单
   */
  SUBMIT = 'SUBMIT',
  /**
   * 打印
   */
  PRINT = 'PRINT',
  /**
   * 切换标签页
   */
  SWITCH_TAB = 'SWITCH_TAB',
  /**
   * 关闭当前页
   */
  ESC = 'ESC',
  /**
   * 回车
   */
  ENTER = 'ENTER',
  /**
   * 打开快速添加
   */
  FAST_ADD = 'FAST_ADD',
}

/**
 * 交通工具
 */
export const Vehicle = {
  FJ: 'FJ',
  HC: 'HC',
  GT: 'GT',
  DBC: 'DBC',
  SNJT: 'SNJT',
  ZJDC: 'ZJDC',
  ZJYC: 'ZJYC',
  ZIGLF: 'ZIGLF',
  LC: 'LC',
}
export const VehicleMap = {
  FJ: '飞机',
  HC: '火车',
  GT: '高铁',
  DBC: '大巴车',
  SNJT: '市内交通',
  ZJDC: '自驾(电车)',
  ZJYC: '自驾(油车)',
  ZIGLF: '自驾-过路过桥费',
  LC: '轮船',
}
/**
 * 报账类型
 */
export const ReimburseType = {
  ZCBX: 'ZCBX',
  ZCSBX: 'ZCSBX',
  JMZXBX: 'JMZXBX',
  RCBX: 'RCBX',
  CCSQ: 'CCSQ',
  CCBX: 'CCBX',
  BT102: 'BT102',
  BT103: 'BT103',

  FL103: 'FL103',
  FL104: 'FL104',
  FL105: 'FL105',

  HKD: 'HKD',
  JKZY: 'JKZY',
  JKZYMD: 'JKZYMD',

  PTBX: 'PTBX',
  HWSQ: 'HWSQ',
  HWBX: 'HWBX',

  ZDSQ: 'ZDSQ',
  ZDBX: 'ZDBX',
  PXSQ: 'PXSQ',
  ZXBX: 'ZXBX',

  XFBX: 'XFBX',

  CSBX: 'CSBX',
  CLBX: 'CLBX',
  ZJLC: 'ZJLC',
  TJMB: 'TJMB',
  // 其他差旅报销单
  QTCL: 'QTCL',
  SKFWH: 'SKFWH',

  BCSQ: 'BCSQ',
  // 推广采购申请单
  TGCGSQ: 'TGCGSQ',
  TGFASQ: 'TGFASQ',
  TGFABG: 'TGFABG',
  TGFAWJ: 'TGFAWJ',
  TGXJDH: 'TGXJDH',
  TGCZ: 'TGCZ',
  TGCZHX: 'TGCZHX',
  TGWLHX: 'TGWLHX',
}

export const ReimburseTypeMap = {
  ZCBX: '资产报销单',
  ZCSBX: '总裁室报销单',
  JMZXBX: '加盟装修支持报销',
  RCBX: '日常报销单',
  CCSQ: '差旅申请单',
  CCBX: '差旅报销单',
  BT102: '借款单',
  BT103: '还款单',

  FL103: '个人借款申请',
  FL104: '单位借款申请',
  FL105: '门店借款申请',

  HKD: '还款',
  JKZY: '借款转移',
  JKZYMD: '借款转移（门店）',

  PTBX: '第三方平台报销单',
  HWSQ: '会务申请单',
  HWBX: '会务报销单',

  ZDSQ: '业务招待申请',
  ZDBX: '业务招待报销单',

  PXSQ: '培训申请',
  ZXBX: '咨询服务费报销单',

  XFBX: '薪酬福利报销单',

  // 外勤单
  WQSQ: '外勤申请单',

  CSBX: '财税报销单',
  CLBX: '车辆运输报销单',
  ZJLC: '资金理财购买申请单',
  TJMB: '加盟保证金退款单',
  SKFWH: '报销收款方申请',

  QTCL: '其他差旅报销单',

  BCSQ: '离职补偿申请',

  TGCGSQ: '采购申请单',
  TGFASQ: '推广方案申请',
  TGFABG: '推广活动变更',
  TGFAWJ: '推广方案完结',
  TGCZ: '推广充值',
  TGXJDH: '推广现金电汇',
  TGCZHX: '推广充值核销',
  TGWLHX: '推广物料/DRP核销',
}
/**
 *  报销单查询跳转对应单据 查看页面
 */
export const ReimburseTypeDetailUrlMap = {
  RCBX: '/advanceApply/dailyReimbursement/detail/',
  CCBX: '/businessTrip/reimbursement/nontravel/detail/',
  PTBX: '/thirdParty/detail/',
  HWBX: '/advanceApply/conference/reimbursement/detail/',
  ZDSQ: '/businessHospitalityRequest/detail/',
  ZDBX: '/businessHospitalityReimbursement/detail/',
  ZXBX: '/consultingservicefee/detail/',
  XFBX: '/welfareReimburse/detail/',
  CSBX: '/expensReimbursement/financeAndTaxation/detail/',
  CLBX: '/vehicleClaimForm/detail/',
  SLBX: '/businessTrip/reimbursement/travel/detail/',
  ZCSBX: '/advanceApply/presidentOfficeReimbursement/detail/',
}
/**
 * 费用类型
 */
export const FeeType = {
  FL001: 'FL001',
  FL002: 'FL002',
  FL003: 'FL003',
  FL004: 'FL004',
  FL005: 'FL005',
  FL006: 'FL006',
  FL007: 'FL007',
  FL008: 'FL008',
  FL009: 'FL009',
  FL108: 'FL108',
  FL057: 'FL057',
  FL113: 'FL113',
}
export const FeeTypeMap = {
  FL001: '日常差旅',
  FL002: '商旅结算',
  FL003: '国外差旅',
  FL004: '加班费用',
  FL005: '展会差旅',
  FL006: '法务维权',
  FL007: '会务差旅',
  FL008: '年终巡查',
  FL009: '驻外',
  FL108: '外勤报销',
  FL057: '租房报销',
  FL113: '其他差旅',
}

/**
 * 差旅申请费用项
 */
export const CCSQExpenseItem = {
  F0001: 'F0001',
  F0002: 'F0002',
  F0003: 'F0003',
  F0025: 'F0025',
  F0005: 'F0005',
  F1034: 'F1034',
}
export const CCSQExpenseItemMap = {
  F0001: '交通费',
  F0002: '住宿费',
  F0003: '差旅补贴',
  F0025: '住宿费-固定',
  F0005: '误餐费',
  F1034: '其他差旅',
}
/**
 * 差旅申请单状态
 */
export const BusinessTripApplyStatusMap = {
  '00': '草稿',
  10: '审批中',
  20: '已驳回',
  21: '点对点驳回',
  30: '审批通过',
  40: '已作废',
  50: '已商旅结算',
  60: '已个人报销',
  70: '全部报销',
}

export const BusinessTripApplyStatus = {
  DRAFT: '00',
  IN_REVIEW: '10',
  REJECTED: '20',
  POINT_TO_POINT_REJECT: '21',
  APPROVED: '30',
  INVALID: '40',
  TRAVEL_SETTLEMENT: '50',
  PERSONAL_REIMBURSEMENT: '60',
  FULL_REIMBURSEMENT: '70',
}

export const InvoiceTypeMap = {
  10100: '增值税专用发票',
  10101: '增值税普通发票',
  10102: '增值税电子普通发票',
  10103: '增值税普通发票(卷票)',
  10104: '机动车销售统一发票',
  10105: '二手车销售统一发票',
  '10505a': '船票',
  10200: '定额发票',
  10400: '机打发票',
  10500: '出租车发票',
  10503: '火车票',
  10505: '客运汽车',
  10506: '航空运输电子客票行程单',
  10107: '数电票(增值税专用发票）',
  10507: '过路费发票',
  10900: '可报销其他发票',
  10108: '数电票(普通发票)',
  '10102a': '区块链电子发票',
  '10102b': '通行费电子普通发票',
  '10100a': '电子专用发票',
  20100: '国际小票',
  20105: '滴滴出行行程单',
  10902: '完税证明',
}

export const basicInfoFieldOfComponent = {
  applyUser: '申请人',
  deptNameLink: '申请部门',
  applyDate: '申请日期',
  billNo: '流程编号',
}

export const IDCard = {
  ID: 'ID',
  CHINESE_PASSPORT: 'CHINESE_PASSPORT',
  HONG_KONG_ID: 'HONG_KONG_ID',
  TAIWAN_ID: 'TAIWAN_ID',
  FOREIGN_PASSPORT: 'FOREIGN_PASSPORT',
}
export const IDCardMap = {
  ID: '身份证',
  CHINESE_PASSPORT: '中国护照',
  HONG_KONG_ID: '港澳居民来往内地通行证',
  TAIWAN_ID: '台湾居民来大陆通行证',
  FOREIGN_PASSPORT: '外国护照',
}

export const PromotionTypeMap = {
  ATL: 'ATL',
  BTL: 'BTL',
  MARKET: '市场推广',
}
/**
 * 推广方案查询：相关的单据状态类型
 */
export const PromotionStatusMap = {
  '00': '草稿',
  '10': '审批中',
  '20': '已驳回',
  '21': '点对点驳回',
  '30': '审批通过',
  '40': '已作废',
}
